button.ant-btn,
a.ant-btn {
  font-family: 'dm-sans', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  .anticon {
    font-size: 16px;
    margin-right: 6px;
  }

  &.rotate-icon > .anticon {
    rotate: 180deg;
  }

  &:hover {
    color: $primary-color;
    box-shadow: 0 0 0 1px $primary-color inset;
  }

  &:focus {
    color: $primary-color;
  }

  &.ant-btn-primary {
    &:focus {
      color: #FFFFFF;
    }

    &:hover {
      color: #FFFFFF;
      box-shadow: 0 0 0 1px $primary-color inset;
    }
  }

  &.ant-btn-icon-only {
    background: transparent;
    width: auto;
    height: auto;

    .anticon {
      margin-right: 0;
    }

    &:hover {
      color: #27cb1f;
      background: transparent;
      box-shadow: none;
    }

    &:focus {
      color: #27cb1f;
      background: transparent;
      box-shadow: none;
    }
  }

  &.ant-btn-dangerous {
    background: rgba(189, 10, 10, 0.15);
    border: 1px solid rgba(189, 10, 10, 0.15);

    &:hover, &:focus, &:disabled {
      background: rgba(189, 10, 10, 0.15) !important;
      border: 1px solid rgba(189, 10, 10, 0.15) !important;
      color: #FF0000;
      box-shadow: 0 0 0 1px #FF0000 inset;
    }
  }

  &.ant-btn-ghost {
    font-family: dm-mono, sans-serif;
    color: #7d7d7d;

    &:hover {
      border: none;
      box-shadow: none;
      color: $grey-x-light;
    }

    &:focus {
      background: transparent;
    }
  }

  &.ant-btn-link {
    &:focus {
      background: transparent;
    }
  }

  &.btn-animate {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-2px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(2px, 0, 0);
    }
  }
}

// Radio buttons
.ant-radio-group {
  &.radio-green {
    .ant-radio-button-wrapper {
      background: $btn-default-bg !important;
      color: $primary-color;
    }
  }

  .ant-radio-button-wrapper {
    padding: 0 20px;
    border-radius: 8px !important;
    border: none !important;
    align-items: center;
    display: inline-flex;

    &:not(:last-child) {
      margin-right: 10px !important;
    }

    &:not(:first-child)::before {
      width: 0;
    }

    &:hover, &:focus, &:checked {
      box-shadow: 0 0 0 1px $primary-color inset !important;
    }

    &:focus-within {
      box-shadow: none;
    }

    &.ant-radio-button-wrapper-checked {
      box-shadow: 0 0 0 1px $primary-color inset !important;
    }
  }

  &.ghost {
    .ant-radio-button-wrapper {
      background: transparent;
      border: none !important;
      box-shadow: none !important;
    }
  }
}
