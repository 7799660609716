.ant-list-item {
  gap: 20px;

  &-clickable {
    &:hover > div {
      cursor: pointer;
      background-color: #2900000d;
    }
  }

  & > div {
    list-style: none;
    background-color: #0015290d;
    border-radius: 8px;
    padding: 10px;
    width: 100%;
  }
}