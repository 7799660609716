.hide-upload {
  display: none;
}

.macaroon-container {
  position: relative;

  .reveal-text {
    position: absolute;
    top: 40%;
    left: 44%;
    z-index: 20;
    font-size: larger;

    &__hidden {
      display: none;
    }
  }

  .macaroon-hidden {
    filter: blur(4px);
  }
}

