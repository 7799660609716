// Unfortunately we have to repeat the color's declarations to use them in scss
// To keep in sync with the ones declared in antd-overrides.less
$primary-color: #1890ff;
$link-color: #1890ff;
$success-color: #52c41a;
$warning-color: #faad14;
$error-color: #f5222d;
$btn-default-bg: #1f381d;
$grey-x-dark: #252525;
$grey-dark: #333333;
$grey-medium: #525252;
$grey-light: #666666;
$grey-x-light: #c6c6c6;