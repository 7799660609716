p {
  font-family: dm-mono, sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
}

.mono-uppercase {
  font-family: dm-mono, sans-serif;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
}