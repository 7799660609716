#layout-main {
  min-height: 100vh;

  .ant-layout-header {
    margin-bottom: 60px;
  }

  .ant-layout-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 22px;
  }

  .ant-layout-footer {
    padding: 20px 0;
  }

  .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
  }

  .app-pages {
    justify-content: center;
    flex: 1
  }
}