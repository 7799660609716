@font-face {
  font-family: 'dm-sans';
  font-style: normal;
  src: url('../fonts/DMSans-Regular.woff2') format('woff2'),
  url('../fonts/DMSans-Regular.woff') format('woff');
}

@font-face {
  font-family: 'dm-sans';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/DMSans-Bold.woff2') format('woff2'),
  url('../fonts/DMSans-Bold.woff') format('woff');
}

@font-face {
  font-family: 'dm-mono';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/DMMono-Regular.woff2') format('woff2'),
  url('../fonts/DMMono-Regular.woff') format('woff');
}

@font-face {
  font-family: 'dm-mono';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/DMMono-Medium.woff2') format('woff2'),
  url('../fonts/DMMono-Medium.woff') format('woff');
}